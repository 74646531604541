<template>
  <div style="padding: 20px">
    <!--    搜索框-->
    <div style="display: flex;align-items: center;margin-bottom: 6px">
      <el-button style="margin-right: 10px" type="primary" @click="createDialog = true">添加</el-button>
      <select-area @areaListId="getAreaListId"/>
      <el-date-picker
          @change="setDate"
          v-model="query.yearAndMonth"
          :clearable="false"
          type="month"
          placeholder="年月"
          style="width: 150px;margin-right: 10px"
      />
      <el-cascader
          v-model="query.deptIdList"
          @change="setDeptId"
          placeholder="部门"
          :options="deptList"
          collapse-tags
          :collapse-tags-tooltip="true"
          style="width: 180px;margin-right: 10px"
          clearable
          :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"/>
      <el-input v-model="query.areaName" placeholder="请输入校区名字" style="width: 150px;margin-right: 10px"></el-input>
      <el-button :loading="tableDataLoading" icon="Search" type="primary" @click="selectTableData">搜索</el-button>
      <el-button icon="Upload" type="primary" @click="importDialog = true">导入</el-button>
      <el-button icon="Download" type="primary" @click="exportData">导出</el-button>
    </div>
    <!--    列表展示-->
    <el-table :data="tableData" height="580px" style="width: 100%" v-loading="tableDataLoading">
      <el-table-column prop="id" label="id" width="80"/>
      <el-table-column label="城市">
        <template #default="scope">
          <span>{{ scope.row.city }}-{{ scope.row.flag }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="deptName" label="部门"/>
      <el-table-column prop="parentDeptName" label="上级部门"/>
      <el-table-column prop="areaName" label="校区"/>
      <el-table-column prop="targetMoney" label="目标"/>
      <el-table-column prop="remainingMoney" label="截止到今日剩余金额"/>
      <el-table-column prop="endDayThisMoney" label="当日课消金额"/>
      <el-table-column prop="thisMoney" label="累计完成课消金额"/>
      <el-table-column prop="differenceMoney" label="差额"/>
      <el-table-column label="完成率">
        <template #default="scope">
          <span>{{ scope.row.accomplishRate }}%</span>
        </template>
      </el-table-column>
      <el-table-column label="年月">
        <template #default="scope">
          <span>{{ scope.row.year }}-{{ scope.row.month }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <el-button @click="clickTargetEdit(scope.row,$event)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--    分页组件-->
    <div style="display: flex;justify-content: end;margin-top: 20px">
      <el-pagination
          v-model:current-page="query.pageIndex"
          v-model:page-size="query.pageSize"
          :page-sizes="[20, 50, 100, 150, 200, 250, 300]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="selectTableData"
          @current-change="selectTableData"
      />
    </div>
    <!--    导入课销目标-->
    <el-dialog
        v-model="importDialog"
        width="500px"
        :append-to-body="true"
        :draggable="true"
    >
      <div style="display: flex; justify-content: center">
        <el-upload
            drag
            :limit="1"
            :auto-upload="false"
            :on-change="fileChange"
            :on-exceed="exceedFile"
            :file-list="fileList"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div style="display: flex; justify-content: center; padding-top: 30px">
        <el-button type="primary" icon="Upload" :loading="importLoading" @click="importData">开始导入</el-button>
      </div>
      <div>
        <a href="https://codeimg.yanhuotiyu.com/MinProgramImg/%E8%AF%BE%E6%B6%88%E7%9B%AE%E6%A0%87%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx">模板下载</a>
      </div>
    </el-dialog>
    <!--    新增课销目标-->
    <el-dialog draggable
               v-model="createDialog"
               width="800"
               title="新增课消目标"
               append-to-body>
      <el-form :rules="addFormRules" ref="addFormRules" :model="addForm">
        <el-row>
          <el-col :span="16">
            <el-form-item label="校区" prop="areaId" label-width="100px">
              <select-area @areaListId="setChoiceCampus" :multiple="false"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="9">
            <el-form-item label="日期" prop="yearAndMonth" label-width="100px">
              <el-date-picker
                  v-model="addForm.yearAndMonth"
                  type="month"
                  placeholder="年月"
              />
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="目标" prop="targetMoney" label-width="100px">
              <el-input v-model="addForm.targetMoney" type="number"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="createDialog = false">取 消</el-button>
          <el-button type="primary" @click="confirm">确 定</el-button>
        </div>
      </template>
    </el-dialog>

    <!--修改课销目标-->
    <el-dialog draggable
               v-model="editDialogShow"
               width="800"
               title="编辑课消目标"
               append-to-body>
      <el-form :model="editDialogData">
        <el-row>
          <el-col :span="16">
            <el-form-item label="校区" label-width="100px">
              <el-input v-model="editDialogData.areaName" disabled/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="9">
            <el-form-item label="日期" label-width="100px">
              <el-input v-model="editDialogData.year" style="width: 55px" disabled/>
              年
              <el-input v-model="editDialogData.month" style="width: 40px" disabled/>
              月
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="目标" label-width="100px">
              <el-input v-model="editDialogData.targetMoney" type="number"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="editDialogShow = false">取 消</el-button>
          <el-button type="primary" @click="confirmEdit">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {selectTableData, importData, add, exportData, editCourseTarget} from "@/api/courseTarget";
import {listXcxDept} from "@/api/dept";
import SelectArea from "../../components/SelectArea";

export default {
  name: "courseTarget",
  components: {
    SelectArea
  },
  data() {
    return {
      createDialog: false,
      editDialogShow: false,
      deptList: [],
      fileList: [],
      importDialog: false,
      importLoading: false,
      //查询参数
      query: {
        deptId: [],
        deptIdList: [],
        pageSize: 10,
        pageIndex: 1,
        areaName:null,
        areaIdList: [],
        yearAndMonth: null,
        year: null,
        month: null
      },
      //新增目标 实体参数
      addForm: {
        areaId: null,
        targetMoney: null,
        year: null,
        month: null,
        yearAndMonth: null
      },
      //修改目标 实体参数
      editDialogData: {
        id: null,

        areaId: null,
        areaName: null,
        deptId: null,
        deptName: null,
        year: null,
        month: null,

        targetMoney: null
      },
      //添加目标的规则
      addFormRules: {
        areaId: [{required: true, message: '请输入！', trigger: 'change'}],
        targetMoney: [{required: true, message: '请输入！', trigger: 'change'},],
        yearAndMonth: [{required: true, message: '请输入！', trigger: 'change'},],
      },
      total: 0,
      tableData: [],
      tableDataLoading: false
    }
  },
  methods: {
    //选文件
    fileChange(file) {
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      let size = file.size / 1024 / 1024;
      if (extension !== "xlsx") {
        this.fileList = [];
        this.$message.warning("只能上传后缀是.xlsx的文件");
      }
      if (size > 10) {
        this.fileList = [];
        this.$message.warning("文件大小不得超过10M");
      }
      if (extension == "xlsx" && size < 10) {
        this.fileList.push(file.raw);
      }
    },
    // 文件数量检测
    exceedFile(files, fileList) {
      this.$message.warning(`只能选择1个文件，当前共选择了 ${files.length + fileList.length} 个`);
    },
    //执行导入
    importData() {
      this.importLoading = true;
      if (this.fileList.length === 0) {
        this.$message.warning('请上传文件');
      } else {
        let form = new FormData();
        form.append("file", this.fileList[0]);
        importData(form).then(val => {
          this.importLoading = false;
          if (val.code == 200) {
            this.repetitionData = val.data;
            this.$message.success("导入成功！");
          } else {
            this.$message.error("导入失败！");
          }
        })
      }
    },
    //选择日期
    setDate(val) {
      let date = this.dateFormat(val);
      this.query.year = date[0];
      this.query.month = date[1];
    },
    //获取组件回传的所选部门ID集合
    setDeptId(val) {
      let list = [];
      val.forEach((item) => {
        list.push(item[item.length - 1])
      })
      this.query.deptIdList = list;
    },
    //检索 数据
    selectTableData() {
      this.tableDataLoading = true;
      selectTableData(this.query).then(res => {
        this.tableDataLoading = false;
        this.tableData = res.data.records;
        this.total = res.data.total;
      })
    },
    //获取 选择区域回传的areaIdList集合
    getAreaListId(val) {
      this.query.areaIdList = val;
    },
    //获取 所选校区id
    setChoiceCampus(val) {
      this.addForm.areaId = val;
    },
    //导出数据
    exportData() {
      exportData(this.query);
      this.$message.success("下载成功后将在右上角下载工具里显示！");
    },
    //加载多级部门 树状数据
    getTypeList(commodityType) {
      if (commodityType != null) {
        commodityType.forEach(items => {
          if (items != null) {
            if (items.children != null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },
    //确定 新增 某校区的目标
    confirm() {
      this.$refs['addFormRules'].validate((valid) => {
        if (valid) {
          let list = this.dateFormat(this.addForm.yearAndMonth);
          this.addForm.year = list[0];
          this.addForm.month = list[1];
          add(this.addForm).then(val => {
            this.createDialog = false;
            Object.keys(this.addForm).forEach((key) => (this.addForm[key] = ''));
            if (val.code === 200) {
              this.$message.success("添加成功！");
            } else {
              this.$message.error(val.message);
            }
          })
        }
      })
    },
    //确认修改 某校区的目标金额
    confirmEdit() {
      editCourseTarget(this.editDialogData).then(val => {
        this.editDialogShow = false;
        //清空 各项的值
        // Object.keys(this.editDialogData).forEach((key) => (this.editDialogData[key] = ''));
        if (val.code === 200) {
          this.$message.success("修改成功！");
        } else {
          this.$message.error("修改失败-该功能未开放");
        }
      }).catch(err => {
        this.$message.error("修改失败-出错啦" + err);
      });
    },
    //点击编辑课销目标的框-修改
    clickTargetEdit(row, e) {
      this.editDialogData.id = row.id;
      this.editDialogData.areaId = row.areaId;
      this.editDialogData.areaName = row.areaName;
      this.editDialogData.deptId = row.deptId;
      this.editDialogData.deptName = row.deptName;
      this.editDialogData.year = row.year;
      this.editDialogData.month = row.month;
      this.editDialogData.targetMoney = row.targetMoney;

      console.log(e);

      this.editDialogShow = true;
    },
    //日期格式化
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      return [y, m];
    },
  },
  created() {
    // this.selectTableData();
    let now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth() + 1; // 月份是从0开始计数的，所以需要加1
    this.query.year = year;
    this.query.month = month;
    this.query.yearAndMonth = year + '-' + month;
    listXcxDept().then(res => {
      this.deptList = this.getTypeList(res.data);
    })
  }
}
</script>

<style scoped>

</style>
