import request from '../utils/request';

/**
 * 导入业绩目标
 * @param query
 * @returns {AxiosPromise}
 */
export const importData = (file) => {
    return request({
        url: '/sys/hourConsumeTarget/importHourConsumeTarget',
        headers: {
            "Content-type": "multipart/form-data",
        },
        method: 'post',
        data: file
    });
};
/**
 * 导出
 * @param query
 * @returns {*}
 */
export function exportData(query) {
    return request({
        url: '/sys/hourConsumeTarget/exportHourConsumeTarget',
        method: 'post',
        data: query,
        responseType: 'arraybuffer'
    })
}
/**
 * 添加课消目标
 */
export const add = (item) => {
    return request({
        url: '/sys/hourConsumeTarget/addHourConsumeTarget',
        method: 'POST',
        data: item
    });
};

/**
 * 修改某校区的课消目标
 */
export const editCourseTarget = (item) => {
    return request({
        url: '/sys/hourConsumeTarget/editCourseTarget',
        method: 'POST',
        data: item
    });
};

/**
 * 查询课消目标列表数据
 * @param list
 * @returns {*}
 */
export const selectTableData = (query) => {
    return request({
        url: '/sys/hourConsumeTarget/getPageList',
        method: 'POST',
        data: query,
    });
};

/**
 * 查询课消目标统计
 * @param list
 * @returns {*}
 */
export const courseTargetStatistics = (query) => {
    return request({
        url: '/sys/hourConsumeTarget/statisticsByDeptId',
        method: 'POST',
        data: query,
    });
};
